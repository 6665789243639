import React from 'react';

export const ClassMerge = ({ newClassName, setNewClassName, handleClassNameSubmit }) => (
  <div className="p-4 shadow-md rounded-md flex flex-col bg-gray-700 text-white">
    <label className="block mb-2">Enter a new class name to merge redundant classes:</label>
    <input
      type="text"
      placeholder="New class name"
      className="w-full mb-4 p-2 border bg-gray-600 border-gray-500 text-white rounded-md"
      value={newClassName}
      onChange={(e) => setNewClassName(e.target.value)}
    />
    <button
      className="w-full p-2 rounded-full transition bg-[#4088FF] text-white hover:bg-[#68A9FF] shadow-lg hover:scale-102"
      onClick={handleClassNameSubmit}
    >
      Submit Class Name
    </button>
  </div>
);
