import React from 'react';

export const DataIntegrityManagement = ({
  actionType,
  setActionType,
  className,
  setClassName,
  propertyName,
  setPropertyName,
  newName,
  setNewName,
  newType,
  setNewType,
  constraintType,
  setConstraintType,
  schema,
  applyRename,
  applySetType,
  applyAddConstraint,
  applyRemoveConstraint,
  setStep,
}) => (
  <div className="p-4 shadow-md rounded-md flex flex-col bg-gray-700 text-white">
    <h2 className="text-lg font-semibold mb-4">Data Integrity Management</h2>
    
    {/* Select Action Type */}
    <label>Select an Action:</label>
    <select
      value={actionType}
      onChange={(e) => setActionType(e.target.value)}
      className="mb-4 p-2 border bg-gray-600 border-gray-500 text-white rounded-md"
    >
      <option value="">Choose action</option>
      <option value="rename">Rename</option>
      <option value="set_type">Set Type</option>
      <option value="add_constraint">Add Constraint</option>
      <option value="remove_constraint">Remove Constraint</option>
    </select>

    {/* Dynamic Form Fields based on selected action */}
    {actionType === "rename" && (
      <div>
        <select
          value={className}
          onChange={(e) => setClassName(e.target.value)}
          className="mb-2 p-2 border bg-gray-600 border-gray-500 text-white rounded-md"
        >
          <option value="">Select Class</option>
          {schema?.classes.map((cls, index) => (
            <option key={index} value={cls.class_name}>{cls.class_name}</option>
          ))}
        </select>
        <input
          type="text"
          placeholder="Property Name (optional)"
          value={propertyName}
          onChange={(e) => setPropertyName(e.target.value)}
          className="mb-2 p-2 border bg-gray-600 border-gray-500 text-white rounded-md"
        />
        <input
          type="text"
          placeholder="New Name"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          className="mb-2 p-2 border bg-gray-600 border-gray-500 text-white rounded-md"
        />
        <button
          onClick={applyRename}
          className="w-full p-2 rounded-full transition bg-[#4088FF] text-white hover:bg-[#68A9FF] shadow-lg mt-4"
        >
          Apply Rename
        </button>
      </div>
    )}

    {actionType === "set_type" && (
      <div>
        <select
          value={className}
          onChange={(e) => setClassName(e.target.value)}
          className="mb-2 p-2 border bg-gray-600 border-gray-500 text-white rounded-md"
        >
          <option value="">Select Class</option>
          {schema?.classes.map((cls, index) => (
            <option key={index} value={cls.class_name}>{cls.class_name}</option>
          ))}
        </select>
        <select
          value={propertyName}
          onChange={(e) => setPropertyName(e.target.value)}
          className="mb-2 p-2 border bg-gray-600 border-gray-500 text-white rounded-md"
        >
          <option value="">Select Property</option>
          {schema?.classes.find(cls => cls.class_name === className)?.properties.map((prop, index) => (
            <option key={index} value={prop.property}>{prop.property}</option>
          ))}
        </select>
        <input
          type="text"
          placeholder="New Data Type"
          value={newType}
          onChange={(e) => setNewType(e.target.value)}
          className="mb-2 p-2 border bg-gray-600 border-gray-500 text-white rounded-md"
        />
        <button
          onClick={applySetType}
          className="w-full p-2 rounded-full transition bg-[#4088FF] text-white hover:bg-[#68A9FF] shadow-lg mt-4"
        >
          Apply Data Type Change
        </button>
      </div>
    )}

    {actionType === "add_constraint" && (
      <div>
        <select
          value={className}
          onChange={(e) => setClassName(e.target.value)}
          className="mb-2 p-2 border bg-gray-600 border-gray-500 text-white rounded-md"
        >
          <option value="">Select Class</option>
          {schema?.classes.map((cls, index) => (
            <option key={index} value={cls.class_name}>{cls.class_name}</option>
          ))}
        </select>
        <select
          value={propertyName}
          onChange={(e) => setPropertyName(e.target.value)}
          className="mb-2 p-2 border bg-gray-600 border-gray-500 text-white rounded-md"
        >
          <option value="">Select Property</option>
          {schema?.classes.find(cls => cls.class_name === className)?.properties.map((prop, index) => (
            <option key={index} value={prop.property}>{prop.property}</option>
          ))}
        </select>
        <select
          value={constraintType}
          onChange={(e) => setConstraintType(e.target.value)}
          className="mb-2 p-2 border bg-gray-600 border-gray-500 text-white rounded-md"
        >
          <option value="">Select Constraint</option>
          <option value="not_null">Not Null</option>
          <option value="unique">Unique</option>
        </select>
        <button
          onClick={applyAddConstraint}
          className="w-full p-2 rounded-full transition bg-[#4088FF] text-white hover:bg-[#68A9FF] shadow-lg mt-4"
        >
          Apply Constraint
        </button>
      </div>
    )}

    {actionType === "remove_constraint" && (
      <div>
        <select
          value={className}
          onChange={(e) => setClassName(e.target.value)}
          className="mb-2 p-2 border bg-gray-600 border-gray-500 text-white rounded-md"
        >
          <option value="">Select Class</option>
          {schema?.classes.map((cls, index) => (
            <option key={index} value={cls.class_name}>{cls.class_name}</option>
          ))}
        </select>
        <select
          value={propertyName}
          onChange={(e) => setPropertyName(e.target.value)}
          className="mb-2 p-2 border bg-gray-600 border-gray-500 text-white rounded-md"
        >
          <option value="">Select Property</option>
          {schema?.classes.find(cls => cls.class_name === className)?.properties.map((prop, index) => (
            <option key={index} value={prop.property}>{prop.property}</option>
          ))}
        </select>
        <select
          value={constraintType}
          onChange={(e) => setConstraintType(e.target.value)}
          className="mb-2 p-2 border bg-gray-600 border-gray-500 text-white rounded-md"
        >
          <option value="">Select Constraint to Remove</option>
          <option value="not_null">Not Null</option>
          <option value="unique">Unique</option>
        </select>
        <button
          onClick={applyRemoveConstraint}
          className="w-full p-2 rounded-full transition bg-[#4088FF] text-white hover:bg-[#68A9FF] shadow-lg mt-4"
        >
          Remove Constraint
        </button>
      </div>
    )}

    {/* Continue Button */}
    <button
      onClick={() => setStep(12)}
      className="w-full p-2 rounded-full transition bg-[#68A9FF] text-white hover:bg-[#4088FF] shadow-lg mt-4"
    >
      Continue
    </button>
  </div>
);
