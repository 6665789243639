import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import JSONPretty from 'react-json-pretty';
import '../App.css';
import { Link } from 'react-router-dom';

const ResultComponent = () => {
  const location = useLocation();
  const { state } = location;
  const { schema, businessType, description } = state || {};

  // State to manage feedback message and its color
  const [feedback, setFeedback] = useState(null);

  const saveSchema = async () => {
    try {
      const response = await fetch('https://bizznizz.eu/api/result', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ schema, business_type: businessType, description }),
      });

      if (response.ok) {
        const result = await response.json();
        setFeedback({ message: `Schema saved successfully: ${result.file_path}`, color: 'green' });
      } else {
        setFeedback({ message: 'Failed to save schema.', color: 'red' });
      }
    } catch (error) {
      console.error('Error saving schema:', error);
      setFeedback({ message: 'An error occurred while saving the schema.', color: 'red' });
    }
  };

  return (
    <div className="flex flex-col h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 text-white">
      {/* Navbar */}
      <nav className="p-4 flex justify-between items-center bg-gray-800 shadow-lg">
        <div className="flex items-center">
          <img src="./logo.png" alt="Logo" className="h-10 w-10 mr-2" />
          <h1 className="text-3xl font-extrabold text-[#68A9FF] tracking-wide">
            BizzNizz AI Builder
          </h1>
        </div>
        <ul className="flex items-center space-x-6">
          <li>
            <Link to="/" className="hover:text-[#68A9FF] transition font-montserrat font-bold text-white">
              Home
            </Link>
          </li>
        </ul>
      </nav>
      
      {/* Main Content */}
      <div className="flex-grow p-6 overflow-auto">
        <h2 className="text-3xl font-semibold mb-4">Final Schema</h2>
        <div className="bg-gray-800 rounded-lg p-4 shadow-lg">
          {schema ? (
            <JSONPretty data={schema} />
          ) : (
            <p className="text-gray-400">No schema generated yet.</p>
          )}
        </div>
        
        <button onClick={saveSchema} className="mt-4 px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded">
          Save schema
        </button>
        
        {/* Feedback Message */}
        {feedback && (
          <p className="mt-4" style={{ color: feedback.color }}>
            {feedback.message}
          </p>
        )}
      </div>
    </div>
  );
};

export default ResultComponent;
