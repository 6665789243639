import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import businessTypesData from './business_types.json';
import { ToastContainer, Toast } from 'react-bootstrap';
import {
  fetchSuggestedClassesApi,
  fetchSuggestedPropertiesApi,
  addSuggestedClassApi,
  generateSchemaApi,
  getFeedbackApi,
  renameEntityApi,
  setTypeApi,
  addConstraintApi,
  removeConstraintApi,
  applyRelationshipApi,
  applyRedundancyFeedbackApi,
  applyNormalizationApi,
  removeClassApi,
  removePropertyApi,
  addClassToSchemaApi,
  addPropertiesToClassApi
} from '../api/api';

import { BusinessTypeAndDescription } from './steps/BusinessTypeAndDescription';
import { UserFeedbackPrompt } from './steps/UserFeedbackPrompt';
import { ClassMerge } from './steps/ClassMerge';
import { RelationshipFeedbackPrompt } from './steps/RelationshipFeedbackPrompt';
import { ApplyManualRelationship } from './steps/ApplyManualRelationship';
import { RelationshipConfirmation } from './steps/RelationshipConfirmation';
import { ClassHandling } from './steps/ClassHandling';
import { PropertyHandling } from './steps/PropertyHandling';
import { SchemaReview } from './steps/SchemaReview';
import { DataIntegrityManagement } from './steps/DataIntegrityManagement';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import JSONPretty from 'react-json-pretty';
import '../App.css';

// Initial State and Effects
const ChatBot = () => {
  const [businessType, setBusinessType] = useState('');
  const [description, setDescription] = useState('');
  const [schema_data, setSchemaData] = useState(null);
  const [chatHistory, setChatHistory] = useState([
    { from: 'bot', message: 'Welcome to BizzNizz AI Builder.' },
    { from: 'bot', message: 'Please provide your business type and your business structure description.' },
  ]);
  const [step, setStep] = useState(1);
  const [feedback, setFeedback] = useState(null);
  const [newClassName, setNewClassName] = useState('');
  const [userResponse, setUserResponse] = useState('');
  const [categoryIndex, setCategoryIndex] = useState(0);
  const [relationships, setRelationships] = useState([]);
  const [relationshipInput, setRelationshipInput] = useState('');
  const [sourceSuggestions, setSourceSuggestions] = useState([]);
  const [targetSuggestions, setTargetSuggestions] = useState([]);
  const [isSelectingTarget, setIsSelectingTarget] = useState(false);
  const [normalizationFeedback, setNormalizationFeedback] = useState([]);
  const [selectedNormalizationIndices, setSelectedNormalizationIndices] = useState([]);
  const [additionalClassName, setAdditionalClassName] = useState('');
  const [newClassProperties, setNewClassProperties] = useState([
    { property: '', data_type: '', primary_key: false, foreign_key: false, not_null: false, references: '' }
  ]);  
  const [targetClass, setTargetClass] = useState('');
  const [newProperties, setNewProperties] = useState([
    { property: '', data_type: '', primary_key: false, foreign_key: false, not_null: false, references: '' }
  ]);  
  const [suggestedClasses, setSuggestedClasses] = useState([]);
  const [suggestedProperties, setSuggestedProperties] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSuggestedProperty, setSelectedSuggestedProperty] = useState(null);
  const [existingClassName, setExistingClassName] = useState('');
  const [filteredBusinessTypes, setFilteredBusinessTypes] = useState([]);
  const [applyRelationshipsFeedback, setApplyRelationshipsFeedback] = useState(null);
  const [sourceClass, setSourceClass] = useState('');
  const [actionType, setActionType] = useState('');
  const [className, setClassName] = useState('');
  const [propertyName, setPropertyName] = useState('');
  const [newName, setNewName] = useState('');
  const [newType, setNewType] = useState('');
  const [constraintType, setConstraintType] = useState('');
  const [selectedProperty, setSelectedProperty] = useState('');
  const [classes, setClasses] = useState([]);
  const [properties, setProperties] = useState([]);
  const [toastMessage, setToastMessage] = useState('');
  const chatContainerRef = useRef(null);
  const categories = ['Redundancy', 'Relationships', 'Normalization', 'Data Integrity'];
  const navigate = useNavigate();


  useEffect(() => {
    if (step === 2 && schema_data) handleFeedbackRequest();
  }, [step, schema_data, categoryIndex]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatHistory]);

  useEffect(() => {
    if (businessType) {
      const filtered = businessTypesData.filter(type =>
        type.business_type.toLowerCase().includes(businessType.toLowerCase())
      );
      setFilteredBusinessTypes(filtered);
    } else {
      setFilteredBusinessTypes([]);
    }
  }, [businessType]);

  useEffect(() => {
    if (schema_data) {
      setClasses(schema_data.classes.map(cls => cls.class_name));
    }
  }, [schema_data]);
  
  const handleSignOut = () => {
    localStorage.removeItem('token'); // Clear token from localStorage
    navigate('/login'); // Redirect to login page
  };

  // Helper Functions
  const chatbotResponse = (message) => {
    if (!message.trim()) return;
    setChatHistory((prev) => [...prev, { from: 'bot', message }]);
  };

  const userChatResponse = (message) => {
    if (!message.trim()) return;
    setChatHistory((prev) => [...prev, { from: 'user', message }]);
  };

  const showToast = (message) => {
    setToastMessage(message);
    setTimeout(() => setToastMessage(''), 3000);
  };

  // Step Navigation and Input Handlers
  const handleClassChange = (className) => {
    setSelectedClass(className);
    const cls = schema_data.classes.find(cls => cls.class_name === className);
    setProperties(cls ? cls.properties.map(prop => prop.property) : []);
    setSelectedProperty('');
  };

  const moveToNextCategory = () => {
    if (categoryIndex + 1 < categories.length) {
      setCategoryIndex((prev) => prev + 1);
      setStep(2);
    } else if (categoryIndex === categories.length - 1) {
      setStep(13);
    } else {
      chatbotResponse("All feedback categories processed.");
      setStep(12);
    }
  };

  const handleUserFeedbackResponse = async (apply) => {
    if (apply.toLowerCase() === 'yes') {
      if (categories[categoryIndex] === 'Relationships') {
        chatbotResponse('Please provide the source class and target class in the format: source_class,target_class');
        setStep(8);
      } else if (categories[categoryIndex] === 'Data Integrity') {
        chatbotResponse('Please proceed with data integrity modifications.');
        setStep(13);
      }
    } else {
      chatbotResponse('Skipping feedback...');
      moveToNextCategory();
    }
  };

  const handleInputChange = (value) => {
    setRelationshipInput(value);

    const classes = value.split(',').map(cls => cls.trim());
    const isSelectingTarget = classes.length > 1 && classes[0];

    if (!isSelectingTarget) {
      setSourceSuggestions(
        schema_data.classes.filter(cls => cls.class_name.toLowerCase().startsWith(classes[0].toLowerCase()))
      );
      setTargetSuggestions([]);
    } else {
      const targetStart = classes[1].toLowerCase();
      setTargetSuggestions(
        schema_data.classes.filter(cls => cls.class_name.toLowerCase().startsWith(targetStart))
      );
      setSourceSuggestions([]);
    }
  };

  const handleSelectSuggestion = (suggestion) => {
    if (!isSelectingTarget) {
      setRelationshipInput(suggestion + ', ');
      setSourceSuggestions([]);
      setIsSelectingTarget(true);
    } else {
      setRelationshipInput(relationshipInput + suggestion);
      setTargetSuggestions([]);
      setIsSelectingTarget(false);
    }
  };

  // API Calls
  const fetchSuggestedClasses = async () => {
    try {
      const suggestedClasses = await fetchSuggestedClassesApi(businessType);
      setSuggestedClasses(suggestedClasses);

      const classList = suggestedClasses.map(cls => `- ${cls}`).join('\n');
      chatbotResponse(`AI Suggested Classes:\n${classList}`);
    } catch (error) {
      chatbotResponse('Error fetching suggested classes.');
    }
  };

  const fetchSuggestedProperties = async () => {
    try {
      const suggestedProperties = await fetchSuggestedPropertiesApi(className, schema_data, businessType);
      setSuggestedProperties(suggestedProperties);

      const propertyList = suggestedProperties.map((prop, index) => (
        <span
          key={index}
          className="cursor-pointer text-blue-400 hover:underline"
          onClick={() => addSuggestedProperty(prop)}
        >
          - {prop.property}: {prop.data_type}
        </span>
      ));
      chatbotResponse(`AI Suggested Properties for ${className}:`);
      chatbotResponse(<div>{propertyList}</div>);
    } catch (error) {
      chatbotResponse('Error fetching suggested properties.');
    }
  };

  const handleBusinessSubmit = async () => {
    if (!businessType || !description) return;
    const userMessage = `Business Type: ${businessType}\nDescription: ${description}`;
    setChatHistory((prev) => [...prev, { from: 'user', message: userMessage }]);
    setChatHistory((prev) => [...prev, { from: 'bot', message: 'Generating schema based on your description...' }]);

    try {
      const generatedSchema = await generateSchemaApi(businessType, description);
      setSchemaData(generatedSchema);
      setChatHistory((prev) => [
        ...prev,
        { from: 'bot', message: "Here's the initial schema generated based on your description." },
      ]);
      setStep(2);
    } catch (error) {
      chatbotResponse('Error generating schema.');
    }
  };

  const handleFeedbackRequest = async () => {
    const category = categories[categoryIndex];
    chatbotResponse(`Checking ${category.toLowerCase()}...`);
  
    try {
      const feedbackData = await getFeedbackApi(schema_data, category, businessType);
      const feedbackResponse = feedbackData.feedback;
      
      console.log(`Feedback Data for ${category}:`, feedbackData);
      console.log(`Feedback Response for ${category}:`, feedbackResponse);
  
      let feedbackActions = [];
  
      if (typeof feedbackResponse === 'string') {
        feedbackActions = [feedbackResponse];
      } else if (Array.isArray(feedbackResponse)) {
        feedbackActions = feedbackResponse.map(f => f.action?.trim() || '').filter(action => action);
      }
  
      console.log(`Parsed Feedback Actions:`, feedbackActions);
  
      // Check if the feedback indicates no issues
      const hasFeedback = feedbackActions.some(action =>
        action && !action.toLowerCase().includes(`no ${category.toLowerCase()} detected`)
      );
  
      if (!hasFeedback || feedbackData.skip_apply) {
        chatbotResponse(`No issues detected in ${category}, moving on to the next category...`);
        moveToNextCategory();
      } else {
        chatbotResponse(`Feedback for ${category}:\n${feedbackActions.map((a, i) => `${i + 1}. ${a}`).join('\n')}`);
        setFeedback(feedbackResponse);
        setStep(category === 'Redundancy' ? 5 : 6);
      }
    } catch (error) {
      console.error(`Error checking ${category}:`, error);
      chatbotResponse(`Error checking ${category}.`);
      moveToNextCategory();
    }
  };
  
  
  

  // Feedback Application and Integrity Management
  const applyRename = async () => {
    try {
      const updatedSchema = await renameEntityApi(schema_data, className, propertyName, newName);
      setSchemaData(updatedSchema);
      showToast(`Renamed ${propertyName} to ${newName}`);
    } catch (error) {
      chatbotResponse(`Error renaming: ${error.response?.data?.detail || error.message}`);
    }
  };

  const applySetType = async () => {
    try {
      const updatedSchema = await setTypeApi(schema_data, className, propertyName, newType);
      setSchemaData(updatedSchema);
      showToast(`New type: ${newType} set for ${propertyName} in class ${className}`);
    } catch (error) {
      chatbotResponse(`Error setting type: ${error.response?.data?.detail || error.message}`);
    }
  };

  const applyAddConstraint = async () => {
    try {
      const updatedSchema = await addConstraintApi(schema_data, className, propertyName, constraintType);
      setSchemaData(updatedSchema);
      showToast(`Constraint ${constraintType} added to ${selectedClass}.${selectedProperty}.`);
    } catch (error) {
      chatbotResponse(`Error adding constraint: ${error.response?.data?.detail || error.message}`);
    }
  };

  const applyRemoveConstraint = async () => {
    try {
      const updatedSchema = await removeConstraintApi(schema_data, className, propertyName, constraintType);
      setSchemaData(updatedSchema);
      showToast(`Constraint ${constraintType} removed from ${selectedClass}.${selectedProperty}.`);
    } catch (error) {
      chatbotResponse(`Error removing constraint: ${error.response?.data?.detail || error.message}`);
    }
  };

  const handleApplyManualRelationship = async () => {
    const [source_class, target_class] = relationshipInput.split(',');
    if (!source_class || !target_class) {
      chatbotResponse('Error: Please provide both source and target classes separated by a comma.');
      return;
    }
  
    try {
      chatbotResponse(`Applying relationship between ${source_class.trim()} and ${target_class.trim()}...`);
      const updatedSchema = await applyRelationshipApi(schema_data, source_class.trim(), target_class.trim());
      setSchemaData(updatedSchema);
      chatbotResponse('Relationship successfully applied.');
    } catch (error) {
      console.error('Error applying relationship:', error);
      chatbotResponse('Error applying the relationship.');
    }
  };
  
  
  
  const handleClassNameSubmit = async () => {
    chatbotResponse('Applying redundancy feedback...');
    const feedbackObject = feedback[0];
  
    // Log the feedback object
    console.log('Feedback object:', feedbackObject);
  
    try {
      const updatedSchema = await applyRedundancyFeedbackApi(schema_data, feedbackObject, newClassName);
      setSchemaData(updatedSchema);
      chatbotResponse('Redundancy feedback applied successfully.');
      moveToNextCategory();
    } catch (error) {
      console.error('Error applying redundancy feedback:', error.response?.data || error.message);
      chatbotResponse('Error applying redundancy feedback.');
    }
  };
  

  const handleNormalizationSelection = async () => {
    const selectedIndices = userResponse
      .split(',')
      .map(num => parseInt(num.trim(), 10) - 1)
      .filter(num => num >= 0 && num < normalizationFeedback.length);

    setSelectedNormalizationIndices(selectedIndices);
    chatbotResponse('Please provide new property names and data types for the selected normalization points.');
    setStep(9);
  };

  const applyNormalizationChanges = async () => {
    const entries = userResponse.split(',').map(entry => entry.trim());
    const newPropertiesArray = [];
    const dataTypesArray = [];

    entries.forEach(entry => {
      const [property, dataType] = entry.split(':').map(part => part.trim());
      if (property && dataType) {
        newPropertiesArray.push(property);
        dataTypesArray.push(dataType);
      }
    });

    if (!newPropertiesArray.length || !dataTypesArray.length) {
      chatbotResponse('Error: Please provide valid property names and data types.');
      return;
    }

    try {
      for (const idx of selectedNormalizationIndices) {
        const feedbackItem = normalizationFeedback[idx];
        const updatedSchema = await applyNormalizationApi(schema_data, feedbackItem, newPropertiesArray, dataTypesArray);
        setSchemaData(updatedSchema);
        chatbotResponse('Normalization applied successfully.');
        moveToNextCategory();
      }
    } catch (error) {
      chatbotResponse('Error applying normalization feedback.');
    }
  };

  const handleSelectSuggestedClass = (className) => {
    const trimmedClassName = className.trim();
    setSelectedClass(trimmedClassName);
    setAdditionalClassName(trimmedClassName); // Automatically fill in the class name field
    fetchSuggestedProperties(trimmedClassName);
  };

  // Additional Class and Property Management
  const addClassToSchema = async () => {
    for (const property of newClassProperties) {
      if (!property.property || !property.data_type) {
        chatbotResponse('Error: Property name and data type cannot be empty.');
        return;
      }
    }

    try {
      const updatedSchema = await addClassToSchemaApi(schema_data, additionalClassName, newClassProperties);
      setSchemaData(updatedSchema);
      chatbotResponse(`Class '${additionalClassName}' added successfully.`);
      setStep(12);
    } catch (error) {
      chatbotResponse('Error adding class to schema.');
    }
  };

  const addPropertiesToClass = async () => {
    for (const property of newProperties) {
      if (!property.property || !property.data_type) {
        chatbotResponse('Error: Property name and data type cannot be empty.');
        return;
      }
    }

    try {
      const updatedSchema = await addPropertiesToClassApi(schema_data, existingClassName, newProperties);
      setSchemaData(updatedSchema);
      chatbotResponse(`Properties added to class '${targetClass}' successfully.`);
      setStep(12);
    } catch (error) {
      chatbotResponse('Error adding properties to class.');
    }
  };

  const addSuggestedProperty = (prop) => {
    // Find the first empty property slot, if any
    const emptySlotIndex = newClassProperties.findIndex(
      (property) => !property.property && !property.data_type
    );
  
    if (emptySlotIndex !== -1) {
      // If there's an empty slot, fill it in
      setNewClassProperties((prev) => {
        const updatedProperties = [...prev];
        updatedProperties[emptySlotIndex] = {
          ...updatedProperties[emptySlotIndex],
          property: prop.property,
          data_type: prop.data_type,
          primary_key: prop.primary_key || false,
          foreign_key: prop.foreign_key || false,
          not_null: prop.not_null || false,
          references: prop.references || '',
        };
        return updatedProperties;
      });
    } else {
      // Otherwise, add a new property entry
      setNewClassProperties((prev) => [
        ...prev,
        {
          property: prop.property,
          data_type: prop.data_type,
          primary_key: prop.primary_key || false,
          foreign_key: prop.foreign_key || false,
          not_null: prop.not_null || false,
          references: prop.references || '',
        },
      ]);
    }
  };

  const handleAddClassProperty = () => {
    setNewClassProperties([...newClassProperties, { name: '', type: '' }]);
  };

  const handleClassPropertyChange = (index, field, value) => {
    const updatedProperties = [...newClassProperties];
    updatedProperties[index][field] = value;
    setNewClassProperties(updatedProperties);
  };

  const handleAddPropertyToClass = () => {
    setNewProperties([...newProperties, { name: '', type: '' }]);
  };

  const handlePropertyChange = (index, field, value) => {
    const updatedProperties = [...newProperties];
    updatedProperties[index][field] = value;
    setNewProperties(updatedProperties);
  };

  const handleRemoveClass = async (className) => {
    try {
      const updatedSchema = await removeClassApi(schema_data, className);
      setSchemaData(updatedSchema);
      showToast(`Class '${className}' removed successfully.`);
    } catch (error) {
      chatbotResponse('Error removing class.');
    }
  };

  const handleRemoveProperty = async (className, propertyName) => {
    try {
      const updatedSchema = await removePropertyApi(schema_data, className, propertyName);
      setSchemaData(updatedSchema);
      showToast(`Property '${propertyName}' removed from '${className}' successfully.`);
    } catch (error) {
      chatbotResponse('Error removing property.');
    }
  };

  const handleRemoveConstraint = async (className, propertyName, constraintType) => {
    try {
      const updatedSchema = await removeConstraintApi(schema_data, className, propertyName, constraintType);
      setSchemaData(updatedSchema);
      showToast(`Constraint '${constraintType}' removed from '${className}.${propertyName}' successfully.`);
    } catch (error) {
      chatbotResponse('Error removing constraint.');
    }
  };

  
// In the main component return structure
return (
  <div className="flex flex-col md:flex-row h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 text-white">
    {/* Main ChatBot Interface */}
    <div className="flex flex-col w-full md:w-2/3 h-full relative">
      {/* Navbar */}
      <nav className="p-4 flex justify-between items-center w-full shadow-lg bg-gray-800 text-white">
        <div className="flex items-center">
          <img src="./logo.png" alt="Logo" className="h-10 w-10 mr-2" />
          <h1 className="text-2xl font-extrabold tracking-wide text-[#68A9FF] drop-shadow-lg">
            BizzNizz AI Builder
          </h1>
        </div>
        <div className="flex items-center space-x-6">
          <Link to="/" className="hover:text-[#68A9FF] transition font-montserrat font-bold text-white">
            Home
          </Link>
          <button
            onClick={handleSignOut}
            className="px-4 py-2 bg-red-600 text-white rounded-full hover:bg-red-700 transition-transform transform hover:scale-105"
          >
            Sign Out
          </button>
        </div>
      </nav>

      {/* Chat History Display */}
      <div ref={chatContainerRef} className="flex-1 overflow-y-auto p-6 bg-gray-900">
        {chatHistory.map((chat, index) => (
          <div key={index} className={`flex items-start mb-4 ${chat.from === 'bot' ? 'flex-row' : 'flex-row-reverse'}`}>
            {chat.from === 'bot' && <img src="./logo.png" alt="AI Logo" className="h-8 w-8 mr-2" />}
            <div className={`max-w-lg rounded-xl p-4 shadow-lg ${chat.from === 'bot' ? 'bg-[#4088FF] text-white' : 'bg-gray-800 text-white'} text-sm`}>
              {chat.message.split('\n').map((line, lineIndex) => (
                <span key={lineIndex}>{line}<br /></span>
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* Dynamic Step-Based Interface */}
      <div className="p-4 bg-gray-800 shadow-lg rounded-t-lg">
        {(() => {
          switch (step) {
            case 1:
              return (
                <BusinessTypeAndDescription
                  businessType={businessType}
                  setBusinessType={setBusinessType}
                  description={description}
                  setDescription={setDescription}
                  filteredBusinessTypes={filteredBusinessTypes}
                  setFilteredBusinessTypes={setFilteredBusinessTypes}
                  businessTypesData={businessTypesData}
                  handleBusinessSubmit={handleBusinessSubmit}
                />
              );
            case 3:
              return (
                <UserFeedbackPrompt
                  userResponse={userResponse}
                  setUserResponse={setUserResponse}
                  handleUserFeedbackResponse={handleUserFeedbackResponse}
                />
              );
            case 5:
              return (
                <ClassMerge
                  newClassName={newClassName}
                  setNewClassName={setNewClassName}
                  handleClassNameSubmit={handleClassNameSubmit}
                />
              );
            case 6:
              return (
                <RelationshipFeedbackPrompt
                  userResponse={userResponse}
                  setUserResponse={setUserResponse}
                  handleUserFeedbackResponse={handleUserFeedbackResponse}
                />
              );
            case 7:
              return (
                <RelationshipConfirmation
                  userResponse={userResponse}
                  setUserResponse={setUserResponse}
                  handleUserFeedbackResponse={handleUserFeedbackResponse}
                />
              );
            case 8:
              return (
                <ApplyManualRelationship
                  relationshipInput={relationshipInput}
                  handleInputChange={handleInputChange}
                  sourceSuggestions={sourceSuggestions}
                  targetSuggestions={targetSuggestions}
                  setRelationshipInput={setRelationshipInput}
                  setSourceSuggestions={setSourceSuggestions}
                  setIsSelectingTarget={setIsSelectingTarget}
                  setTargetSuggestions={setTargetSuggestions}
                  handleApplyManualRelationship={handleApplyManualRelationship}
                  proceedToNextStep={moveToNextCategory}
                />
              );
            case 10:
              return (
                <ClassHandling
                  additionalClassName={additionalClassName}
                  setAdditionalClassName={setAdditionalClassName}
                  fetchSuggestedClasses={fetchSuggestedClasses}
                  suggestedClasses={suggestedClasses}
                  handleSelectSuggestedClass={handleSelectSuggestedClass}
                  suggestedProperties={suggestedProperties}
                  addSuggestedProperty={addSuggestedProperty}
                  newClassProperties={newClassProperties}
                  handleClassPropertyChange={handleClassPropertyChange}
                  setNewClassProperties={setNewClassProperties}
                  handleAddClassProperty={handleAddClassProperty}
                  addClassToSchema={addClassToSchema}
                  schema={schema_data}
                  handleRemoveClass={handleRemoveClass}
                />
              );
            case 11:
              return (
                <PropertyHandling
                  existingClassName={existingClassName}
                  setExistingClassName={setExistingClassName}
                  schema={schema_data}
                  newProperties={newProperties}
                  handlePropertyChange={handlePropertyChange}
                  setNewProperties={setNewProperties}
                  handleAddPropertyToClass={handleAddPropertyToClass}
                  addPropertiesToClass={addPropertiesToClass}
                  selectedProperty={selectedProperty}
                  setSelectedProperty={setSelectedProperty}
                  handleRemoveProperty={handleRemoveProperty}
                />
              );
            case 12:
              return (
                <SchemaReview
                  schema={schema_data}
                  chatbotResponse={chatbotResponse}
                  setStep={setStep}
                  businessType={businessType}
                  description={description}
                />
              );
            case 13:
              return (
                <DataIntegrityManagement
                  actionType={actionType}
                  setActionType={setActionType}
                  className={className}
                  setClassName={setClassName}
                  propertyName={propertyName}
                  setPropertyName={setPropertyName}
                  newName={newName}
                  setNewName={setNewName}
                  newType={newType}
                  setNewType={setNewType}
                  constraintType={constraintType}
                  setConstraintType={setConstraintType}
                  schema={schema_data}
                  applyRename={applyRename}
                  applySetType={applySetType}
                  applyAddConstraint={applyAddConstraint}
                  applyRemoveConstraint={applyRemoveConstraint}
                  setStep={setStep}
                />
              );
            default:
              return null;
          }
        })()}
      </div>
    </div>

    {/* Live Schema Display */}
    <div className="w-full md:w-1/3 h-screen p-6 bg-gray-900 text-white border-l border-gray-800 overflow-y-auto">
      <h2 className="text-lg font-semibold mb-4">Live Schema</h2>
      <div className="p-4 rounded-lg bg-gray-800">
        {schema_data ? (
          <JSONPretty data={schema_data} />
        ) : (
          "No schema generated yet."
        )}
      </div>
    </div>
  </div>
);

};
export default ChatBot;
