import React from 'react';

export const BusinessTypeAndDescription = ({
  businessType,
  setBusinessType,
  description,
  setDescription,
  filteredBusinessTypes,
  setFilteredBusinessTypes,
  businessTypesData,
  handleBusinessSubmit,
}) => (
  <div className="p-4 shadow-md rounded-md flex flex-col bg-gray-700 text-white relative">
    <div className="relative w-full">
      <input
        type="text"
        placeholder="Enter business type"
        className="w-full mb-2 p-2 border bg-gray-600 border-gray-500 text-white rounded-md"
        value={businessType}
        onChange={(e) => {
          setBusinessType(e.target.value);
          const inputValue = e.target.value.toLowerCase();
          const filtered = businessTypesData.filter(item =>
            item.business_type.toLowerCase().includes(inputValue)
          );
          setFilteredBusinessTypes(filtered);
        }}
      />
      {filteredBusinessTypes.length > 0 && (
        <div className="absolute z-10 bg-gray-700 rounded-md w-full max-h-60 overflow-y-auto" style={{ top: '100%', marginTop: '0.5rem' }}>
          {filteredBusinessTypes.map((item, index) => (
            <div
              key={index}
              className="p-2 hover:bg-gray-600 cursor-pointer"
              onClick={() => {
                setBusinessType(item.business_type);
                setFilteredBusinessTypes([]);
              }}
            >
              {item.business_type}
            </div>
          ))}
        </div>
      )}
    </div>

    <textarea
      placeholder="Enter business description"
      className="w-full h-32 mb-4 p-2 border bg-gray-600 border-gray-500 text-white rounded-md"
      value={description}
      onChange={(e) => setDescription(e.target.value)}
    />

    <button
      className="w-full p-2 rounded-full transition bg-[#4088FF] text-white hover:bg-[#68A9FF] shadow-lg hover:scale-102"
      onClick={handleBusinessSubmit}
    >
      Generate Schema
    </button>
  </div>
);

export default BusinessTypeAndDescription;
