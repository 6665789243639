import React from 'react';
import { Link } from 'react-router-dom';

export const SchemaReview = ({ schema, chatbotResponse, setStep, businessType, description }) => (
  <div className="p-4 shadow-md rounded-md flex flex-col bg-gray-700 text-white">
    <h2 className="text-xl mb-4">Schema Refinement Complete</h2>
    
    <button
      className="w-full p-2 rounded-full transition bg-[#4088FF] text-white hover:bg-[#68A9FF] shadow-lg mb-4"
      onClick={() => chatbotResponse(JSON.stringify(schema, null, 2))}
    >
      View Final Schema
    </button>
    
    <button
      className="w-full p-2 rounded-full transition bg-[#4088FF] text-white hover:bg-[#68A9FF] shadow-lg mb-4"
      onClick={() => setStep(10)} // Go to add new class step
    >
      Handle Classes
    </button>
    
    <button
      className="w-full p-2 rounded-full transition bg-[#4088FF] text-white hover:bg-[#68A9FF] shadow-lg mb-4"
      onClick={() => setStep(11)} // Go to add properties to an existing class step
    >
      Handle Properties
    </button>
    
    <Link
      to="/result"
      state={{ schema, businessType, description }} // Pass schema, businessType, and description
    >
      <button
        className="w-full p-2 rounded-full transition bg-[#68A9FF] text-white hover:bg-[#4088FF] shadow-lg"
      >
        Finish
      </button>
    </Link>
  </div>
);
